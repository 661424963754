import React from 'react'
import styled from 'styled-components'
import Block from './Block'
import stars from '../assets/images/five-stars.svg'
import QuoteGridBlock from './G2QuoteGridBlock'

const Testimonials = () => {
  return (
    <TestimonialsContainer>
      <Block type="padding-large">
        <QuotesGrid>
          <QuoteGridBlock
            quote="Swarmia taps into our toolchain - like Slack, JIRA, GitHub - and provides actionable insights for our teams. The combination of nudges through Slack to help pay attention to outliers in the daily work and some killer analytics features, like showing time investment into different stories on a calendar, really help carve out the inefficacies."
            name="Otto V."
            title="VP of Engineering"
            photo={stars}
            company="Mid-Market (51-1000 emp.)"
          />
          <QuoteGridBlock
            quote="Swarmia reduces the need to keep actively searching for information on what is happening and where. With Swarmia you can trust that the important information reaches you when the event or actions happen."
            name="Tiina R."
            title="Software Engineering Team Lead"
            photo={stars}
            company="Small-Business (50 or fewer emp.)"
          />
          <QuoteGridBlock
            quote="Swarmia makes it simple to gather engineering metrics in one place; this gives great visibility to the engineering team and helps them understand and analyse their performance, allowing them to define ways to improve. Without Swarmia, achieving the same would require a lot of manual effort and spreadsheets."
            name="Andrea M."
            title="Head of Engineering"
            photo={stars}
            company="Small-Business (50 or fewer emp.)"
          />
          <QuoteGridBlock
            quote="Swarmia reduces me hours of research to get complete reports, such as batches, deployments. I use it on a monthly basis. The integration is easy to do, the implementation is quick, you'll get the information right away and their customer support team helps a lot. An ease to use this tool."
            name="Verified User"
            title="Information Technology and Services"
            photo={stars}
            company="Enterprise (> 1000 emp.)"
          />
          <QuoteGridBlock
            quote="Swarmia was so easy to set up, and it keeps me apprised of our DORA metrics in a way that doesn't feel overwhelming. It allows me to see where we're getting stuck, and how we can improve our efficiency. I love the reporting on stale branches or if we need to review PRs faster. I have it linked to Slack and so I see reports every day."
            name="Elena N."
            title="Co-founder & CTPO"
            photo={stars}
            company="Small-Business (50 or fewer emp.)"
          />
          <QuoteGridBlock
            quote="Swarmia provides insightful data and metrics that allow team leaders to gain a deep understanding of their team's performance. The comprehensive reports help identify bottlenecks, optimize workflows, and make data-driven decisions to improve productivity continuously. This data-driven approach has greatly enhanced our ability to meet deadlines and deliver high-quality results."
            name="Ville W."
            photo={stars}
            title="CTO"
            company="Mid-Market (51-1000 emp.)"
          />
        </QuotesGrid>
      </Block>
    </TestimonialsContainer>
  )
}

export default Testimonials

const TestimonialsContainer = styled.div`
  h3 {
    text-align: center;
  }
`

const QuotesGrid = styled.div`
  -webkit-columns: 360px 3;
  -moz-columns: 360px 3;
  columns: 360px 3;
  -webkit-column-gap: 24px;
  -moz-column-gap: 24px;
  column-gap: 24px;
  margin: 0 auto;
  overflow: visible;
  display: block;
  -moz-column-fill: balance;
  column-fill: balance;
`
